<template>
  <div class="page-auth">
    <div class="auth-background"></div>

    <!-- Navbar -->
    <Navbar />

    <!-- Sidebar -->
    <Sidebar />

    <!-- Sign In -->
    <section id="auth" class="auth">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-6">
            <h5 data-en="WELCOME BACK," data-id="SELAMAT DATANG,">
              WELCOME BACK,
            </h5>
            <h1>SPACE WALKER</h1>

            <form @submit.prevent="login" class="mt-4 mt-xxl-5">
              <div
                class="form-group row align-items-center no-gutters px-3 py-2"
              >
                <div class="col-10 col-md-11">
                  <label for="email">Email</label>
                  <input
                    v-model="form.email"
                    type="email"
                    class="form-control"
                    id="email"
                    required
                  />
                </div>
                <div class="col text-center">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 0C5.824 0 0 5.824 0 13C0 20.176 5.824 26 13 26H19.5V23.4H13C7.358 23.4 2.6 18.642 2.6 13C2.6 7.358 7.358 2.6 13 2.6C18.642 2.6 23.4 7.358 23.4 13V14.859C23.4 15.886 22.477 16.9 21.45 16.9C20.423 16.9 19.5 15.886 19.5 14.859V13C19.5 9.412 16.588 6.5 13 6.5C9.412 6.5 6.5 9.412 6.5 13C6.5 16.588 9.412 19.5 13 19.5C14.794 19.5 16.432 18.772 17.602 17.589C18.447 18.746 19.903 19.5 21.45 19.5C24.011 19.5 26 17.42 26 14.859V13C26 5.824 20.176 0 13 0ZM13 16.9C10.842 16.9 9.1 15.158 9.1 13C9.1 10.842 10.842 9.1 13 9.1C15.158 9.1 16.9 10.842 16.9 13C16.9 15.158 15.158 16.9 13 16.9Z"
                      fill="#101010"
                    />
                  </svg>
                </div>
              </div>

              <div
                class="form-group row align-items-center no-gutters px-3 py-2"
              >
                <div class="col-10 col-md-11">
                  <label for="password">Password</label>
                  <input
                    v-model="form.password"
                    :type="visibility"
                    class="form-control"
                    id="password"
                    required
                  />
                </div>
                <div class="col text-center">
                  <svg
                    width="26"
                    height="23"
                    viewBox="0 0 26 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-password"
                    v-if="visibility == 'password'"
                    @click="showPassword()"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13 0C10.2143 0 7.8 0.742857 5.75714 2.22857L7.24286 3.52857C8.91429 2.41429 10.7714 1.85714 13 1.85714C19.1286 1.85714 24.1429 6.87143 24.1429 13H26C26 5.75714 20.2429 0 13 0ZM0 1.85714L2.97143 4.64286C1.11429 6.87143 0 9.84286 0 13H1.85714C1.85714 10.2143 2.78571 7.8 4.45714 5.94286L8.54286 9.65714C7.8 10.5857 7.42857 11.7 7.42857 13C7.42857 16.1571 9.84286 18.5714 13 18.5714C14.4857 18.5714 15.7857 18.0143 16.7143 17.0857L22.2857 22.2857L23.5857 20.9857L1.3 0.557143L0 1.85714ZM9.84286 10.9571L15.2286 15.9714C14.6714 16.3429 13.9286 16.7143 13 16.7143C10.9571 16.7143 9.28571 15.0429 9.28571 13C9.28571 12.2571 9.47143 11.5143 9.84286 10.9571ZM18.5714 13.9286L16.7143 12.2571C16.3429 10.7714 15.0429 9.47143 13.3714 9.28572L11.5143 7.61429C12.0714 7.42857 12.4429 7.42857 13 7.42857C16.1571 7.42857 18.5714 9.84286 18.5714 13V13.9286Z"
                      fill="#101010"
                    />
                  </svg>

                  <svg
                    width="26"
                    height="19"
                    viewBox="0 0 26 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-password"
                    v-if="visibility == 'text'"
                    @click="hidePassword()"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 13C0 5.75714 5.75714 0 13 0C20.2429 0 26 5.75714 26 13H24.1429C24.1429 6.87143 19.1286 1.85714 13 1.85714C6.87143 1.85714 1.85714 6.87143 1.85714 13H0ZM7.42857 13C7.42857 9.84278 9.84286 7.42857 13 7.42857C16.1572 7.42857 18.5714 9.84278 18.5714 13C18.5714 16.1572 16.1572 18.5714 13 18.5714C9.84286 18.5714 7.42857 16.1572 7.42857 13ZM9.28571 13C9.28571 15.0428 10.9571 16.7143 13 16.7143C15.0428 16.7143 16.7143 15.0428 16.7143 13C16.7143 10.9572 15.0428 9.28571 13 9.28571C10.9571 9.28571 9.28571 10.9572 9.28571 13Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>

              <button
                v-if="!isSubmit"
                type="submit"
                class="btn btn-main btn-block py-3"
              >
                SIGN IN
              </button>
              <button
                v-else
                class="
                  btn btn-main btn-block
                  py-3
                  d-flex
                  justify-content-center
                  align-items-center
                "
                disabled
              >
                <b-spinner class="mx-4"></b-spinner>
              </button>
            </form>
          </div>
        </div>
      </div>

      <Parallax title="SIGN IN" />
    </section>

    <!-- Toggle Mobile -->
    <ToggleMobile />

    <!-- Footer -->
    <Footer isSmall />
  </div>
</template>

<script>
// Library
import { mapState } from "vuex";

// Layouts
import Navbar from "@/components/layouts/Navbar.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";
import Footer from "@/components/layouts/Footer.vue";

// Components
import Parallax from "@/components/components/Parallax.vue";
import ToggleMobile from "@/components/components/ToggleMobile.vue";

// Services
import { capitalize } from "@/services/general.js";
import initParallax from "@/services/parallax/InitParallax.js";

export default {
  name: "SignIn",
  metaInfo: {
    title: "Sign In - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  components: {
    Navbar,
    Sidebar,
    Footer,
    Parallax,
    ToggleMobile,
  },
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
      error: (state) => state.error,
    }),
  },
  watch: {
    user() {
      this.$toasted.success("Login Success", {
        position: "top-right",
        className: "rounded",
        duration: 5000,
      });

      this.$router.push({ name: "Dashboard" });
    },
    error(newValue) {
      this.$toasted.error(capitalize(newValue.message), {
        position: "top-right",
        className: "rounded",
        duration: 5000,
      });
    },
  },
  data() {
    return {
      visibility: "password",
      form: {
        email: "",
        password: "",
      },
      isSubmit: false,
    };
  },
  mounted() {
    initParallax();
  },
  methods: {
    showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
    async login() {
      this.isSubmit = true;
      await this.$store.dispatch("auth/login", this.form).then(() => {
        this.isSubmit = false;
      });
    },
  },
};
</script>